.loading {
  height: 5px;
  width: 90%;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}

.loading::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #005ab4;
  left: -100%;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}