#projector-container {
    position: fixed;
    right: 0;
    background-color: rgba(50, 50, 50, 0.05);
    width: 0;
    height: 100%;

    transition: all .1s linear;
    z-index: 999999999;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    background-blend-mode: overlay;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#projector-container.extended {
    width: 40%;
    height: 100%;
}

.projector-header {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.projector-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.projector-footer {
    flex: 1;
    display: flex;
    align-items: center;
}

#projector-container:not(.extended) .projector-footer {
    margin-right: 70px;
}

.projector-footer-content {
    padding: 10px 20px;
    transition: all .1s linear;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.projector-footer-content .button {
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-device-width: 768px) {
    #projector-container {
        bottom: 0;
        width: 100%;
        
        border-radius: 30px 30px 0 0;
        border: 0;
    
        backdrop-filter: blur(5px);
        background-blend-mode: overlay;

        height: 15%;
    }
  
    #projector-container .botons {
        flex-direction: row;
    }

    #projector-container.extended {
        width: 100%;
        height: 40%;
    }

    #projector-container.full {
        width: 100%;
        height: 100%;
    }
}

.time-now {
    font-size: 24px;
    font-weight: bold;
}

.llista-proves {
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow: auto;
    padding: 10px;
    width: 80%;
}

.prova {
    padding: 12px 20px;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    cursor: pointer;
    transition: all .1s linear;
    border-radius: 10px;
    font-size: 24px;
}


.prova.selected {
    background-color: rgb(50, 50, 50);
    color: white;
}

.prova.done {
    background-color: rgb(175, 175, 175);
    color: white;
}

.prova a {
    text-decoration: none;
    color: inherit;
}

/* New Styles */
#projector-container {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.prova {
    padding: 12px 20px;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.prova.selected {
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.projector-header, .projector-content, .projector-footer {
    padding: 0 20px;
}

/* New Styles */
.prova-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.prova-nom {
    flex: 1;
    font-size: 22px;
    justify-content: center;
    display: flex;
}

.prova-hora {
    flex: 0.25; /* Reduced flex value */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.prova-start, .prova-end {
    font-size: 12px; /* Reduced font size */
}

/* Modified Styles */
.prova {
    padding: 12px 20px;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
}

.goBack {
    position: absolute;
    top: 10px;
    left: 20px;
    cursor: pointer;

    border-radius: 10px;
    background-color: #ccc;
    padding: 10px;
    transition: all .1s linear;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.goBack .goBackButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.goBack .goBackArrow {
    font-size: 20px;
}

.goBack .goBackText {
    font-size: 16px;
}