table.notifications-log {
    text-align: left;
}

table.notifications-log td {
    padding: 0.5rem;
    text-align: left;
    vertical-align: top;
}

table.notifications-log tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

table.notifications-log tbody tr:hover {
    background-color: #ddd;
}

tr.notification.new {
    background-color: #e6f7ff;
    font-weight: bold;
}