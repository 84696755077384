.llista-container {
    overflow-y: scroll;
    position: relative;
}

.llista-name {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.castellers {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
    gap: 5px;
}

.casteller {
    border-radius: 10px;
    padding: 2px;

    display: flex;
    justify-content: space-between;
    position: relative;

    border: solid 3px transparent;
}

.casteller.sense_altura { background-color: #ffffff; color: black; }
.casteller.Falca { background-color: #44CFCB; color: black; }
.casteller.Crossa { background-color: #4EA5D9; color: white; }
.casteller.Baix { background-color: #2A4494; color: white; }
.casteller.Lateral { background-color: #224870; color: white; }
.casteller.Rengla { background-color: #122C34; color: white; }

.casteller.Fitxat { background-color: #2A4494; color: white; }
.casteller.Vinc { background-color: #367b06; color: white; }
.casteller.No-vinc { background-color: #EA3546; color: black; }
.casteller.No-confirmat { background-color: #c0780c; color: black; }

.casteller.selected {
    background-color: #ccc !important;
}

.casteller.matched {
    border: solid 3px #e1b400;
    padding: 5px;
    font-size: 16px;
}

.casteller.noseleccionable {
    opacity: 0.33;
}

.casteller.aviat .nom {
    color: inherit;
}

.casteller.tard .nom {
    color: inherit;
}

.casteller .nom {
    height: 100%;
    padding: 10px;
    position: relative;
}

.casteller .altura {
    background-color: rgb(255, 255, 255, 0.2);
    border-left: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 0 5px 5px 0;
    padding: 10px;
    display: flex;
    align-items: center;
}

.casteller.hovered {
    border: solid 3px #ba6900 !important;
}

.individual-display {
    border-radius: 5px;
    padding: 2px 4px;
    background-color: darkseagreen;
    color: white;
}

.individual-display.relative {
    position: absolute;

    top: -5px;
    right: -5px;
}

.casteller .time-indicators {
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    gap: 4px;
    font-size: 11px;
}

.casteller .time {
    background-color: rgba(255, 107, 107, 0.9);
    color: white;
    padding: 1px 4px;
    border-radius: 4px;
    white-space: nowrap;
}