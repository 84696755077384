.boto-back {
    margin: 10px;
    padding: 10px;
    text-align: center;
    /* border: solid 1px black; */
    border-radius: 5px;
    background-color: '#eee';
    text-decoration: none;
    display: block;
}

.boto-back a {
    text-decoration: none;
    color: black;
}

.user {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    background-color: #f3f3f3;
    max-width: 200px;
    border-radius: 10px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.edit-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.edit-button button {
    margin: 20px;
    padding: 10px;
    font-size: 16px;
}

.save-buttons {
    display: flex;
    justify-content: space-around;
    margin: 20px;
    align-items: center;
}

.save-buttons button {
    font-size: 18px;
}

.title {
    font-weight: bold;
    padding-right: 20px;
    margin-top: 10px;
}

.crear_usuari_button {
    margin: 10px;
    padding: 10px;
    /* border: solid 1px rgba(0, 0, 0, 0.5); */
    color: black;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    border-radius: 10px;
}