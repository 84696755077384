div {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

    cursor: default;
    font-family: 'Franklin Gothic Medium', Arial, sans-serif;
}

html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
}

#root {
    display: flex;
    justify-content: center;
}