.error {
  border: 1px solid red !important;
}

.error-text {
  color: red;
  font-size: 12px;
}

.delete-button {
  width: 100%;
  font-size: 14px;
  background-color: rgba(255, 0, 0, 0.2);
}

div {
  -webkit-touch-callout: auto; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: auto; /* Konqueror HTML */
      -moz-user-select: auto; /* Old versions of Firefox */
      -ms-user-select: auto; /* Internet Explorer/Edge */
          user-select: auto; /* Non-prefixed version, currently
                              supported by Chrome, Edge, Opera and Firefox */
}

.form-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 80%;
  max-width: 800px;
}

.form-editor-title,
.form-editor-description {
  width: 100%;
}

.form-editor-title input {
  font-size: 24px;
  margin-bottom: 25px;
}

.form-editor-description textarea {
  font-size: 14px;
}

.form-element {
  border: 1px solid #ccc;
  padding: 25px;
  /* margin: 25px 0; */
  border-radius: 5px;
  width: 95%;
}

.form-element-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.option-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 2%;
  width: 100%;
}

input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 10px;
}

.option-input {
  flex-grow: 1;
  margin-right: 10px;
}

input.question {
  font-size: 18px;
}

button.delete {
  background-color: rgba(255, 0, 0, 0.2);
}

button.remove-option {
  background-color: rgba(255, 0, 0, 0.2);
}

label {
  margin-right: 10px;
  width: 60%;
}

.add-element-buttons {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.add-element-buttons button {
  font-size: 14px;
  padding: 10px;
  /* margin: 5px; */
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-element-buttons button:hover {
  background-color: #185abc;
}

.load-save-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.load-save-buttons button {
  font-size: 14px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: #333;
}

.load-save-buttons button:hover {
  background-color: #e0e0e0;
  border-color: #999;
  transform: scale(1.1);
}

.type-label {
  border-radius: 5px;
  background-color: #1a73e8;
  color: white;
  padding: 5px 8px;
  font-size: 14px;
}

.option-label {
  border-radius: 3px;
  background-color: coral;
  color: white;
  padding: 3px 5px;
  font-size: 11px;
}

.fixed-load-save-buttons {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 10px;
}

.fixed-load-save-buttons button {
  font-size: 18px;
  padding: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: #333;
}

.form-preview {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
}

button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}