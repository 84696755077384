.prova {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: solid 1px black;
    padding: 10px;
    margin: 10px;
    gap: 10px;
}

.llista-importer {
    padding-left: 20px;
    padding-right: 20px;
    height: 75%;
}

@media only screen and (min-device-width: 768px) {
    .llista-importer {
        height: 80%;
    }
}

.import-button {
    position: fixed;
    top: 20px;
    z-index: 9999999;
    display: inline-block;
    padding: 15px 40px;
    color: #fff;
    background-color: #16a12d;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media only screen and (min-device-width: 768px) {
    .import-button {
        left: 20px;
    }
}

.import-button:hover {
    background-color: #157a26;
}